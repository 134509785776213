import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Container, Row, Col } from 'reactstrap';
import './presale.css';
import { FaEthereum } from 'react-icons/fa';
import { AppContext } from '../../context/AppContext';

function TokenPresale() {
  const { selectedWallet, setSelectedWallet } = useContext(AppContext);
  const [ethBalance, setEthBalance] = useState(0);
  const [ethAmount, setEthAmount] = useState(0);
  const [tokenReceived, setTokenReceived] = useState(0);
  const [presaleCompleted, setPresaleCompleted] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const currentPrice = 0.08;

  const presaleEndTime = new Date('2024-09-30T23:59:59Z').getTime();

  useEffect(() => {
    setEthBalance(1.234);

    // Countdown timer setup
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = presaleEndTime - now;

      if (distance < 0) {
        clearInterval(timer);
        setPresaleCompleted(true);
      } else {
        setTimeLeft({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [presaleEndTime]);

  const handleMaxClick = () => {
    setEthAmount(ethBalance);
    calculateReceived(ethBalance);
  };

  const calculateReceived = (eth) => {
    setTokenReceived((eth / currentPrice).toFixed(2));
  };

  const handleEthChange = (e) => {
    const value = parseFloat(e.target.value);
    setEthAmount(value);
    calculateReceived(value);
  };

  const handleConnectWallet = async () => {
    setSelectedWallet(!selectedWallet);
  };

  return (
    <Container className="presale-card">
      <>
        <Row className="justify-content-center">
          <Button className="connect-wallet" onClick={handleConnectWallet}>
            <FaEthereum />
            {selectedWallet ? { selectedWallet } : 'Connect Wallet'}
          </Button>
        </Row>

        <Row className="timer-row">
          {!presaleCompleted ? (
            <>
              <h3>Presale ends in</h3>
              <div className="timer">
                <span>{timeLeft.days.toString().padStart(2, '0')}</span>:
                <span>{timeLeft.hours.toString().padStart(2, '0')}</span>:
                <span>{timeLeft.minutes.toString().padStart(2, '0')}</span>:
                <span>{timeLeft.seconds.toString().padStart(2, '0')}</span>
              </div>
            </>
          ) : (
            <>
              <h4 className="alert-heading">Presale Completed</h4>
              <p>
                The presale has successfully ended. Thank you for participating!
              </p>
            </>
          )}
        </Row>

        <Row className="price-row">
          <Col>
            <h5>Current price</h5>
            <h3>${currentPrice.toFixed(3)}</h3>
          </Col>
        </Row>

        <Row className="eth-input-row">
          <Col>
            <h5>Your ETH Balance: {ethBalance} ETH</h5>
            <Input
              type="number"
              value={ethAmount}
              onChange={handleEthChange}
              placeholder="0.0"
            />
            <Button color="primary" onClick={handleMaxClick}>
              Max
            </Button>
          </Col>
        </Row>

        <Row className="received-row">
          <h5>Ploutos you receive: {tokenReceived}</h5>
        </Row>

        <Row className="buy-now-row">
          <Button color="primary" size="lg">
            Buy Now
          </Button>
        </Row>
      </>
    </Container>
  );
}

export default TokenPresale;
